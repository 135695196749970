import React from "react";
import ElectedOfficialsList from "../electedOfficials/official/electedOfficialsList";

export default function RepSearchResults() {
	return (
		<>
			<ElectedOfficialsList/>
		</>
	);
};
